/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import { baseStyles } from '../html/_config';
import { H3 } from '../html/Fonts';
import { Link } from '../html/Links';
import { urlMaker } from '../../utility/blog';

const BlogSidebarArticle = ({ image, url, title }) => ( 
  <Link 
    to={urlMaker(url)}
    css={css`
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      padding-top: 30px;
      border-top: 1px solid ${baseStyles.colorQuaternary};
      &:hover {
        opacity: 0.6;
        &:after {
          width: 0;
        }
      }
    `}
  >
    <section css={css`
      width: 100%;
      height: 100px;
      overflow: hidden;
      margin-bottom: 15px;
    `}>
      <img 
        css={css`max-width: 100%;`}
        src={image} 
        alt='title'
      />
    </section>
    <H3 css={css`margin-bottom: 30px;`}>
      {title}
    </H3>
  </Link>
)

export default BlogSidebarArticle