/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'

// Components
import {Container, Row, Col} from '../html/Layout'
import { H1, H4 } from '../html/Fonts';
import { baseStyles } from '../html/_config';

// Styled Components
const TitleContainer = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  min-height: 150px;
  margin-bottom: 15px;
  padding-right: 15px;
  &::after {
    z-index: -1;
    position: absolute;
    width: calc(100% + 30vw);
    height: 100%;
    top: 0;
    left: -30vw;
    content: '';
    background: ${baseStyles.colorTertiary};

  }
`
const ContentContainer = styled.section`
  position: relative;
  padding: 15px 15px 15px 0;
`


const SectionBlock = ({ title, subtitle, children, img }) => (
  <Container as='section' fluid css={css`padding: 60px 0!important;`}>
    <Row as='section' css={css`max-width: ${baseStyles.maxWidth}px; margin: 0 auto!important;`}>
      <Col xs={7}>
        <TitleContainer>
          <div>
            <H1 css={css`margin-bottom: 0px;`}>{title}</H1>
            <H4 css={css`margin-bottom: 0px; margin-top: 5px;`}>{subtitle}</H4>
          </div>
        </TitleContainer>
        <ContentContainer>
          {children}
        </ContentContainer>
      </Col>
      <Col xs={5} css={css`img { max-width: 100%; }`}>
        {img}
      </Col>
    </Row>
  </Container>
)

export default SectionBlock