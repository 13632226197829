/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'

// Components
import SocialShare from '../features/SocialShare';
import { H3 } from '../html/Fonts';
import { baseStyles } from '../html/_config';

const Container = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 15px;
  border-top: 1px solid ${baseStyles.colorQuaternary}
`

const BlogShareArticle = ({title}) => ( 
  <Container>
    <H3 css={css`margin-bottom: 15px;`}>
      Share This Article
    </H3>
    <SocialShare title={title}/>
  </Container>
)

export default BlogShareArticle