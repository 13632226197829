import {graphql} from 'gatsby'

/** @jsx jsx */
import { css, jsx } from "@emotion/core"

// H31 Components
import Layout from "../components/layout"
import { H1, H2, P, LIH } from "../components/html/Fonts"
import { baseStyles } from "../components/html/_config"
import SEO from "../components/seo"
import SectionBlock from "../components/page-elements/SectionBlock"
import TimeIndicator from "../components/molecules/TimeIndicator"
import ContentBlog from "../components/page-elements/ContentBlog"
import SocialShare from "../components/features/SocialShare"

export default function StoriesOfCare({ data, pageContext }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  // Extract articles for next and previous navigating
  const { nextNext, next, previous, previousPrevious } = pageContext
  let articles = [nextNext, next, previous, previousPrevious]
  articles = articles.filter(article => article != null)

  // Get data for this blog post
  const dataNewBlogPost = {
    title: frontmatter.title,
    time: frontmatter.date,
    img: <img src={frontmatter.image} alt={frontmatter.title}/>,
  }

  return (
    <Layout>
      <SEO title={frontmatter.title} description={markdownRemark.excerpt} />
      <SectionBlock {...dataNewBlogPost}>
        <P
          css={css`
            margin-bottom: 15px;
          `}
        >
          {frontmatter.subtitle}
        </P>
        <TimeIndicator time={frontmatter.date} />
        <section
          css={css`
            margin-top: 45px;
            width: 200px;
          `}
        >
          <SocialShare title={frontmatter.title}/>
        </section>
      </SectionBlock>
      <ContentBlog articles={articles} title={frontmatter.title}>
        <div
          css={css`
            h1 {
              ${H1.__emotion_styles}
            }
            h2 {
              ${H2.__emotion_styles}
            }
            p {
              ${P.__emotion_styles}
            }
            li {
              ${LIH.__emotion_styles}
            }
            img {
              display: block;
              margin: 0 auto;
              max-width: 100%;
            }
            iframe {
              width: 100%;
              height: 433px;
              margin-bottom: ${baseStyles.baseMargin}px;
              @media (max-width: 992px) {
                height: 353px;
              }
              @media (max-width: 500px) {
                height: 265px;
              }
            }
          `}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </ContentBlog>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      id
      excerpt
      html
      frontmatter {
        title
        subtitle
        date
        image
      }
    }
  }
`
