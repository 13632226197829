/** @jsx jsx*/
import { css, jsx } from '@emotion/core'
import styled from '@emotion/styled'

import { 
  FacebookShareButton, 
  TwitterShareButton, 
  EmailShareButton,

  FacebookIcon,
  TwitterIcon,
  EmailIcon
} from 'react-share';

const SocialContainer = styled.section`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
`

const SocialShare = ({ title }) => ( 
  <SocialContainer>
    <FacebookShareButton 
      url={typeof window != 'undefined' ? window.location: '/'}
      title={title}
      css={css`
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      `}
    >
      <FacebookIcon
        size={32}
        round
      />
    </FacebookShareButton>
    <TwitterShareButton 
      url={typeof window != 'undefined' ? window.location: '/'}
      title={title}
      css={css`
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      `}
    >
      <TwitterIcon
        size={32}
        round
      />
    </TwitterShareButton>
    <EmailShareButton 
      url={typeof window != 'undefined' ? window.location: '/'}
      subject={title}
      css={css`
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
      `}
    >
      <EmailIcon
        size={32}
        round
      />
    </EmailShareButton>
  </SocialContainer>
)

export default SocialShare