/** @jsx jsx*/
import { css, jsx } from '@emotion/core'

// Components
import { Container, Row, Col } from '../html/Layout';
import { baseStyles } from '../html/_config';
import BlogShareArticle from '../molecules/BlogShareArticle';
import BlogSidebarArticle from '../molecules/BlogSidebarArticle';

const ContentBlog = ({ children, title, articles=[] }) => (
  <Container as='section' fluid css={css`background: ${baseStyles.colorQuinary};`}>
    <Row>
      <Col xs={0} sm={1} md={2}>
        {articles.map((article, i) => (
          <BlogSidebarArticle key={i} 
            url={article.fields.path}
            title={article.frontmatter.title}
            image={article.frontmatter.image}
          /> 
        ))}
        
        <BlogShareArticle title={title}/>
      </Col>
      <Col 
        xs={12} 
        sm={10} 
        md={8}
        css={css`
          .gatsby-image-wrapper {
            margin-bottom: ${baseStyles.baseMargin}px;
          }
        `}
      >
        {children}
      </Col>
      <Col xs={0} sm={1} md={2}/>
    </Row>
  </Container>
)

export default ContentBlog